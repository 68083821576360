/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.navbar-brand {
    max-width: 180px;
}

.quote-btn {
    padding: 10px 20px !important;
    height: 45px;
    margin: 25px;
    color: #fff !important;
}

.sticky-top .quote-btn {
    margin: 9px 20px;
}

.service-item {
    background-color: #e6f8ff !important;
    border: 2px solid #d4f4ff;
}

@media (max-width: 767.98px) {
    .navbar-brand {
        max-width: 140px;
    }

    .carousel-inner {
        min-height: 400px !important;

        img {
            width: 1000px !important;
        }

        h1 {
            padding: 20px 40px !important;
        }
    }

    nav .quote-btn {
        margin: 5px;
    }

    .py-5 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}